// ================================================================================================
//  File Name: custom-rtl.scss
//  Description: RTL support SCSS file.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Variables
// ------------------------------

@import "bootstrap-extended/include"; // Bootstrap includes
@import "components/include"; // Components includes

@mixin respond($breakpoint) {
  @if $breakpoint ==xs {
    @media only screen and (max-width: 36em) {
      @content;
    }
  }

  @if $breakpoint ==sm {
    @media only screen and (max-width: 48em) {
      @content;
    }
  }

  @if $breakpoint ==md {
    @media only screen and (max-width: 62em) {
      @content;
    }
  }

  @if $breakpoint ==lg {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint ==xl {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }

  @if $breakpoint ==xxl {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

.cd-mcustom-table {
  .table thead th{
    background-color: #03244A !important;
    color: white !important;
  }
}

.site-selected {
  width: 320px !important;
}

.delete-icon {
  margin-left: 16px;
}

.card {
  box-shadow: none !important;
  width: 100% !important;
}

.user-bor {
  border: 1px solid #8080807d !important;

  .user-status {
    text-transform: capitalize;
    font-weight: 300;
    font-size: 12px;
    margin: 0px;
    color: #f2f2ff80;
  }

  .user-name {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 18px;
  }
}

.inbox-cardi-b {
  padding: 3rem 1.5rem !important;
}

.customBadge {
  padding: 20px !important;
  border-radius: 100px !important;
}

.s-text {
  margin-top: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;

  text-align: center;

  color: #68a753;
}

.c-text {
  // margin-top: 40px;
  // padding-top: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 39px;
  text-align: center;

  color: #4a4a4a;
}

.p-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  text-align: center;

  color: #4a4a4a;
}

.bu-inbox {
  padding: 0 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */

  color: #ffffff;
}

.CustomLanguage {
  display: flex;
  align-items: center;

  .languagetext {
    // height: 17px;
    cursor: pointer;
    font-weight: 400;
    font-size: 17px;
  }
}

.collapsToggler {
  height: 29px;
}

.rotate {
  transform: rotate(180deg);
}

.sideline {
  border-left: 1.5px solid #ffffff;
  /* height: 80px; */
  height: calc(100% - 97px);
  position: absolute;
  left: 39px;
  top: 61px;
}

.sideline-ar {
  border-left: 1.5px solid #ffffff;
  /* height: 80px; */
  height: calc(100% - 97px);
  position: absolute;
  left: 39px;
  top: 61px;
}

.user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: rgba(242, 242, 255, 0.5) !important;
  /* identical to box height */

  color: rgba(242, 242, 255, 0.5);
}

.records-items {
  position: absolute;
  width: 5px;
  height: 45px;
  background: #1c2340;
}

.navbarchildicon {
  // margin-right: 16px;
  margin-bottom: 10px;
  margin-left: 9px;
}

.records-number {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #171b1e;
}

.lastnavfooter {
  .Powered {

    // border-width: 1;
    .hr-powered {
      margin-top: -10px !important;
      margin: 1.5rem 0;
      background-color: currentColor;
      border: 0;
    }

    position: fixed;
    // display: flex;
    width: "100%" !important;
    justify-content: center;
    // margin-top: auto;
    bottom: 0;
    // left: 10px;
    // background: #09855a;
    padding: 46px;
    padding-bottom: 26px;
    padding-top: 0 !important;
    color: white;
    font-weight: bold;
    // z-index: 9000;
    background-image: url("../../../assets/images/logo/nv-bg-bo.svg");
    background-size: contain;
    background-repeat: no-repeat;

    .powered-img {
      // margin-bottom: 25px !important;
      margin-top: 20px;
      width: 100%;
      // width: 200px !important;
    }
  }
}

[dir="ltr"] .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing)>a:after {
  margin-top: 5px;
  margin-right: 10px;
}

.nav-item {
  // font-style: normal;
  // font-weight: 600;
  // font-size: 18px;
  // line-height: 22px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.4);
}

[dir] .main-menu.menu-light .navigation>li.open:not(.menu-item-closing)>a,
[dir] .main-menu.menu-light .navigation>li.sidebar-group-active>a {
  border-radius: 5px !important;
}

.logo-b {
  margin-bottom: 50px !important;
}

.customHeader {
  background-color: $secondary;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 1.5rem;
  color: white;
}

.CustomWidth {
  width: 20%;
  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}

.cutom1 {
  width: 16%;

  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
}

.customField {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.formrpeater-hr {
  height: 50px !important;
  margin: 0 11px;
  border: 1px solid;
}

.value-input {
  width: 270px !important;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  border: 0px solid #d8d6de !important;
  // background-color: white !important;
  // // font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #9c9999;
}

.cutom1s {
  width: auto;
  display: flex;
  align-items: center;
  // margin-right: 0.7rem;
  // margin-bottom: 0.7rem;
}

.route-menu {
  margin-top: 50px !important;
}

pre,
code,
kbd,
samp {
  direction: ltr;
}

// Align icons position
.main-menu {

  .navigation li>a>svg,
  .navigation li>a>i,
  .dropdown-menu svg,
  .dropdown-menu i,
  .dropdown-user>a>svg,
  .dropdown-user>a>i,
  .navigation>li>a>svg,
  .navigation>li>a>i {
    // float: right;
  }

  .navigation>li ul li>a {
    display: flex;
    align-items: center;
  }
}

.access-b {
  margin-top: -400px;
}

// [type="tel"],
// [type="url"],
// [type="email"],
// [type="number"] {
//   direction: rtl;
// }

// file uploader
.Attachment {
  border: 1.5px #87898e dashed;
  padding: 1.5rem;
  border-radius: 12px !important;
}

.roundImage {
  background: #ccddff;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.fileView {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding: 0px 16px;
  align-items: center;
  background: #fcfcfd;
  border: 1px solid #dfdfe6;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 1%;
}

.fileImageBg {
  background: #ebeff7;
  padding: 5px !important;
  border-radius: 50px;
}

.custom_slide {
  width: 95%;
  margin-bottom: 5px !important;
}

// Transformed menu icons
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub>a:after,
.vertical-layout.vertical-overlay-menu.menu-open .main-menu .navigation li.has-sub>a:after {
  transform: rotate(180deg);
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing)>a:after {
  transform: rotate(90deg);
}

// Horizontal menu
.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-toggle::after {
  background-image: url(str-replace(
 str-replace($chevron-left, "currentColor", $body-color),
    "#",
    "%23"
    ));
}

// Dropdown RTL Changes
.header-navbar .navbar-container ul.nav li.dropdown {
  .dropdown-menu {
    &::before {
      top: 1px;
    }
  }
}

.repeater-responsive {
  min-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  &_body {
    max-height: 500px;
    overflow: auto;
  }
}

.repeater-overflow {
  overflow: auto;
}

.available-col {
  display: contents !important;
}

.customFieldM {
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.hr-css {
  width: 100%;
  display: none;
}

.CustomWidths {
  width: auto;
  align-items: center;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
  display: flex;
}

.head-form-repeats2 {
  display: flex;
  // justify-content: center;
  margin: 20px 0 0 0 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  /* identical to box height, or 89% */

  color: #000000;
}

.header-navbar {

  .dropdown,
  .dropup {
    .dropdown-menu.dropdown-menu-end::before {
      right: auto;
      left: 0.5rem;
    }
  }
}

.dropdown,
.dropup,
.btn-group {
  .dropdown-menu {
    right: auto !important;
    left: auto !important;

    &.dropdown-menu-end {
      left: 0 !important;

      &::before {
        right: 0.6rem;
        left: auto;
      }
    }
  }
}

.logout-issue {

  .dropdown,
  .dropup,
  .btn-group {
    .dropdown-menu {
      right: 0px !important;
      top: 19px !important;
      left: auto !important;

      &.dropdown-menu-end {
        left: 0 !important;

        &::before {
          right: 0.6rem;
          left: auto;
        }
      }
    }
  }

  .dropdown-menu .dropdown-item {
    width: 100% !important;
  }
}

.dropstart {
  .dropdown-toggle {
    &::before {
      background-image: url(str-replace(
 str-replace($chevron-right, "currentColor", $white),
        "#",
        "%23"
        )) !important;
    }
  }

  .dropdown-menu {
    margin-right: $dropdown_spacing !important;
  }
}

.dropend {
  .dropdown-toggle {
    &::after {
      background-image: url(str-replace(
 str-replace($chevron-left, "currentColor", $white),
        "#",
        "%23"
        )) !important;
    }
  }

  .dropdown-menu {
    left: 0 !important;
    margin-left: $dropdown_spacing !important;
  }
}

// BS Toast
.toast {
  right: auto;
}

// Select2
.select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-search__field {
  direction: rtl;
}

.select2,
.select2-container {
  text-align: right;
}

.apexcharts-canvas .apexcharts-text.apexcharts-yaxis-label {
  transform: translate(14px, 0);
}

// Chartist
.chartjs-render-monitor {
  margin-right: 1rem;
}

// Datatable
div.dataTables_wrapper div.dataTables_filter {
  text-align: left !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  right: 0.45rem;
}

// Datatable responsive modal
.dtr-modal .dtr-modal-close {
  left: 6px;
  right: auto !important;
}

//eod

.eod-form {
  margin-top: 28px;
  font-family: "Poppins";
  font-style: normal;

  .cardhead {
    font-size: 16px !important;
    font-weight: 500px !important;
  }

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2c3131;
  }

  .button-main {
    background-color: #2c3131;

    border-radius: 5px;
  }

  /* Inside auto layout */
}

// Avatar group
.avatar-group {

  // Avatar Group Sizings
  .avatar {
    margin-right: -0.785rem;
    margin-left: 0;
  }

  .avatar-sm {
    margin-right: -0.65rem;
  }

  .avatar-lg {
    margin-right: -1.5rem;
  }

  .avatar-xl {
    margin-right: -1.85rem;
  }
}

// Breadcrumb
.breadcrumb:not([class*="breadcrumb-"]),
.breadcrumb.breadcrumb-chevron {
  .breadcrumb-item+.breadcrumb-item {
    &:before {
      transform: rotate(180deg);
    }
  }
}

// Pagination
.pagination .page-item {

  &.prev-item,
  &.prev,
  &.previous {
    .page-link {
      &:before {
        transform: rotate(180deg);
      }

      &:hover,
      &:active {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    &.disabled {
      .page-link {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.next-item,
  &.next {
    .page-link {
      &:after {
        transform: rotate(180deg);
      }

      &:hover,
      &:active {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    &.disabled {
      .page-link {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
}

code[class*="language-"],
pre[class*="language-"] {
  direction: ltr;
}

@media print {

  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

// Calendar
.fc .fc-header-toolbar .fc-right .fc-button.fc-prev-button .fc-icon {
  right: 4px !important;
}

.fc .fc-header-toolbar .fc-right .fc-button.fc-next-button .fc-icon {
  left: -3px !important;
}

// Popover & Tooltip
.bs-popover-start .popover-arrow::before,
.bs-tooltip-start .tooltip-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $tooltip-bg;
  left: -1px;
}

.bs-tooltip-start .tooltip-arrow::before {
  left: -6px;
}

.bs-popover-start>.popover-arrow::after,
.bs-tooltip-start>.tooltip-arrow::after {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $tooltip-color;
}

.bs-popover-start .popover-arrow,
.bs-tooltip-start .tooltip-arrow,
.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
  right: auto;
  left: 100%;
}

.bs-popover-end .popover-arrow::before,
.bs-tooltip-end .tooltip-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $tooltip-bg;
  right: -1px;
}

.bs-tooltip-end .tooltip-arrow::before {
  right: -6px;
}

.bs-popover-end>.popover-arrow::after,
.bs-tooltip-end>.tooltip-arrow::after {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $tooltip-color;
}

.bs-popover-end .popover-arrow,
.bs-tooltip-end .tooltip-arrow,
.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
  left: auto;
  right: 100%;
}

// Perfect scrollbar RTL fix
body .ps__rail-y {
  right: auto !important;
  left: 1px !important;
}

// FAQ and Pricing page
.faq-navigation img,
.pricing-free-trial .pricing-trial-img {
  transform: scaleX(-1);
}

.feather-chevron-left,
.feather-chevron-right {
  transform: rotate(-180deg) !important;
}

// Kanban
.kanban-application {
  .kanban-container {
    .kanban-item {

      i,
      svg {
        margin-right: 0 !important;
        margin-left: 0.25rem;
      }
    }
  }
}

// Invoice List
.invoice-list-wrapper {
  .dataTables_filter {
    input {
      margin-left: 0 !important;
      margin-right: 0.5rem;
    }
  }

  .dropdown .dropdown-menu.dropdown-menu-end {
    left: 2rem !important;
  }
}

// File Manager
.file-manager-application {
  .sidebar-file-manager {
    .sidebar-inner {
      .my-drive .jstree-node.jstree-closed>.jstree-icon {
        transform: rotate(180deg);
      }
    }
  }
}

//auth

.authLayout {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../../../assets/images/svg/signInBG.svg") !important;
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2% 0;
  align-items: end;
  justify-content: end;

  display: flex;
  flex-direction: column;

  a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
    font-style: normal;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 !important;

    // z-index: 1;
    @include respond(md) {
      top: 130px;
    }
  }

  &__logo {
    min-height: 15vh;

    img {
      width: 12%;
      height: auto;
    }

    @include respond(md) {
      img {
        width: 20%;
      }

      text-align: center;
      margin-top: 1rem;
    }

    @include respond(sm) {
      img {
        width: 35%;
      }
    }
  }

  &__imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;

    img {
      width: 90%;
      height: auto;
      // mix-blend-mode: luminosity;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__formContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 3rem;
    align-items: center;

    &__box {
      width: 100%;

      &__children {
        z-index: 9999;
      }
    }
  }

  &__footer {
    margin-top: 50px;

    img {
      width: 206px;
      height: 91px;
    }
  }
}

.authLayout__formContainer__box {
  padding: 80px;
  background: rgb(26 57 93);

  // filter: blur(8px);
  @include respond(md) {
    padding: 30px;
  }

  border-radius: 15px;

  .login-header {
    text-align: center;

    .heading {
      // font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 64px;
      line-height: 64px;
      text-align: center;

      color: #ffffff;

      @include respond(sm) {
        font-weight: 300;
        font-size: 30px;
      }

      @include respond(md) {
        font-weight: 400;
        font-size: 40px;
      }
    }

    .heading-otherscreeens {
      font-weight: 400;
      font-size: 50px;
      text-align: center;
      color: white;
      line-height: 55px;

      @include respond(sm) {
        font-weight: 300;
        font-size: 30px;
      }

      @include respond(md) {
        font-weight: 400;
        font-size: 40px;
      }
    }

    .info {
      margin-top: 60px;
      margin-bottom: 60px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      color: #ffffff;

      @include respond(md) {
        margin-top: 30px;
        margin-bottom: 40px;
      }

      .email {
        overflow: hidden;
      }
    }
  }

  .loginFields {
    background-color: #04244a !important;
    border-radius: 5px;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    border: none !important;
    color: #ffffff !important;
    // &::-webkit-input-placeholder {
    //   color: white !important;
    //   font-family: "Poppins";
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 14px;
    // }
  }

  .remember-me {
    // display: flex;
    // justify-content: space-between;
    // align-items: baseline;
    margin: 10px 10px;

    small {
      color: #31cd82;
    }

    label {
      color: #ffffff;
      margin-top: 1px;
    }

    input {
      background-color: #959db2;
    }
  }

  .authButton {
    background-color: #10a945 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    border: none;
    height: 60px;
    min-height: 30px;
    padding: 16px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: 10px;
  }

  .cancelButtn {
    // color: #10a945 !important;

    background-color: rgb(26 57 93) !important;
    border: 2px solid #10a945 !important;
    border-radius: 5px;
    margin-top: 20px;
    height: 60px;
    min-height: 30px;
    padding: 16px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;

    &:hover {
      box-shadow: none !important;
    }
  }

  .login-link {
    color: #31cd82 !important;
  }
}

.check-email-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  // margin-top: calc(100vh - 640px);
  &_box {
    background: #1a395d;
    padding: 60px 80px;
    border-radius: 15px;
    text-align: center;

    // position: relative;
    p {
      font-weight: 400;
      font-size: 18px;
      color: white;
    }

    @media (max-width: 515px) {
      padding: 30px 50px;

      p {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  &-icon {
    position: relative;

    figure {
      position: absolute;
      left: -195px;
      top: -27px;

      @media (max-width: 515px) {
        left: -141px;
      }
    }
  }

  &-custombtn {
    width: 385px;
    margin-top: 21px;
    height: 60px;

    @media (max-width: 515px) {
      width: 300px;
    }
  }

  &-fotter {
    display: flex;
    align-items: end;
    justify-content: end;
  }
}

.expand-more-icon {
  transform: rotate(180deg);
}

.switch-wrapper>input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper>input[type="checkbox"]+.switch {
  transform: translateX(5px);
}

.switch-wrapper>input[type="checkbox"]:checked+.switch {
  transform: translateX(50%) translateX(-19px);
}

.switch-wrapper.large>input[type="checkbox"]:checked+.switch {
  transform: translateX(50%) translateX(-35px);
}

.switch-wrapper>input[type="checkbox"]:disabled+.switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.switch-wrapper.large {
  height: 40px;
  width: 80px;
}

.switch-wrapper>.switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}

.switch-wrapper>.switch>.switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 4px;
  width: 14px;
  z-index: 3;
}

.switch-wrapper.large>.switch>.switch-handle {
  height: 30px;
  width: 30px;
}

.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}

.switch-wrapper.large+.switch-label {
  line-height: 4rem;
  margin-left: 15px;
}